/* eslint-disable @next/next/no-img-element */
import { memo } from 'react'

interface Props {
  width?: string
  url: string
  image: string
}

const Tuev = ({ image, url, width }: Props) => (
  <a href={url} target='_blank' rel='noopener noreferrer'>
    <img
      alt='tuev'
      src={image}
      style={{
        width,
        minWidth: width ? 'unset' : '160px',
        maxWidth: '160px',
      }}
      loading='lazy'
    />
  </a>
)

export default memo(Tuev)
