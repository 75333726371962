/* eslint-disable @next/next/no-img-element */
import { memo } from 'react'
import { Typography, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Rating from '@mui/material/Rating'

const useStyles = makeStyles({
  rating: {
    fontSize: '0.625rem',
  },
  numberOfRatings: {
    fontSize: '0.625rem',
    lineHeight: 1.5,
  },
  ratingStars: {
    '& svg': {
      height: '0.75rem',
      width: '0.75rem',
    },
  },
})

interface EkomiProps {
  image: string
  ratings: number
}

const Ekomi = ({ image, ratings }: EkomiProps) => {
  const { numberOfRatings, ratingStars } = useStyles()

  return (
    <Grid container direction='column' alignItems='center'>
      <Grid item>
        <img
          src={image}
          alt='Ausgezeichnete Kundenzufriedenheit Logo'
          loading='lazy'
          height='55px'
        />
      </Grid>
      <Grid item>
        <Typography
          className={numberOfRatings}
          variant='h5'
          color='textSecondary'
        >
          {ratings} / 5
        </Typography>
      </Grid>
      <Grid item>
        <Rating
          className={ratingStars}
          name='read-only'
          value={ratings}
          readOnly
        />
      </Grid>
    </Grid>
  )
}

export default memo(Ekomi)
