/* eslint-disable @next/next/no-img-element */
import { memo } from 'react'
import { Box } from '@mui/material'

import LoanSelectorHeader from './loan-selector-header'

import {
  SmavaBankNameToBankCodeMap,
  getBankLogoByProductId,
} from '../../utils/productMapping.utils'

interface BankLogoProps {
  productId?: string
  bankName?: string
}

const BankLogo = ({ bankName, productId }: BankLogoProps) => {
  const logoFileName =
    SmavaBankNameToBankCodeMap[
      bankName as keyof typeof SmavaBankNameToBankCodeMap
    ]
  const logo = logoFileName
    ? `${process.env.NEXT_PUBLIC_TEAL_CDN_URL}/products/widgets/${logoFileName}.svg`
    : getBankLogoByProductId(productId as string)

  return logoFileName || productId ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        padding: '30px 0',
      }}
    >
      <img src={logo as string} alt='' width='285' />
    </Box>
  ) : (
    <LoanSelectorHeader />
  )
}

export default memo(BankLogo)
