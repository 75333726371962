/* eslint-disable @next/next/no-img-element */
import { memo } from 'react'

const GuenstigerGehtNicht = ({ image }: Record<string, string>) => (
  <img
    src={image}
    alt='Ausgezeichnete Kundenzufriedenheit Logo'
    loading='lazy'
    height='64px'
  />
)

export default memo(GuenstigerGehtNicht)
