// PS: The commented banks on the list below are there for future reference.
// These are the list from old green (smava) platform
// and its not 100% sure if we still have those banks.

import { isSmava } from './getTenantInfo.utils'

interface BankProduct {
  id: number
  name: string
  logo: string
}

const productsData: Record<string, BankProduct[]> = {}

switch (process.env.NEXT_PUBLIC_ENV) {
  case 'development':
  case 'shared':
    import('../public/finanzcheck/products/shared.json').then((data) => {
      productsData['finanzcheck'] = data.products
    })
    import('../public/smava/products/shared.json').then((data) => {
      productsData['smava'] = data.products
    })
    break
  case 'stage':
    import('../public/finanzcheck/products/stage.json').then((data) => {
      productsData['finanzcheck'] = data.products
    })
    import('../public/smava/products/stage.json').then((data) => {
      productsData['smava'] = data.products
    })
    break
  default:
    import('../public/finanzcheck/products/live.json').then((data) => {
      productsData['finanzcheck'] = data.products
    })
    import('../public/smava/products/live.json').then((data) => {
      productsData['smava'] = data.products
    })
    break
}

export enum SmavaBankNameToBankCodeMap {
  abk = 'ABK',
  // advanzia,
  auxmoney = 'AUX',
  bank1822 = '1822direkt',
  bankofscotland = 'BOS',
  barclays = 'BC',
  // bonkredit,
  // carcredit,
  // cashper,
  commerzbank = 'CB',
  // consorsbank,
  // credimaxx,
  creditplus = 'CP',
  degussa = 'DEG',
  deutschebank = 'DB',
  dkb = 'DKB',
  dsl = 'DSL',
  // extrakredit,
  // hvb,
  ingdiba = 'ING',
  kredit2day = 'K2D',
  // kreditprivat,
  // mastercard,
  // maxda,
  norisbank = 'NB',
  oyakankerbank = 'OAB',
  postbank = 'PB',
  // postbankbusinesscredit,
  // psd,
  qlick = 'QLK',
  santander = 'SBC',
  skg = 'SKG',
  // skreditpartner,
  // skreditpartnerauto,
  // skreditpartnerkredit,
  swk = 'SWK',
  targobank = 'TB',
  // vonessensubprime,
  // wunschkredit,
  younited = 'YOU',
}

export const getBankLogoByProductId = (productId: string): string | null => {
  const productIdInt = parseInt(productId, 10)
  const products = isSmava ? productsData['smava'] : productsData['finanzcheck']

  return !!productIdInt
    ? products.filter(({ id }) => id === productIdInt)[0]?.logo
    : null
}
