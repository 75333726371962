import React, { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'

import { DEFAULT_SELECTION_VALUES } from '../utils/loanSelector.utils'
import { getLoanSelectionFromQueryParams } from '../utils/url'
import { getQueryParamsFromPath } from '../utils/getFromPath.utils'

export enum InputNames {
  Purpose = 'purpose',
  VehiclePrice = 'vehiclePrice',
  InitialPayment = 'initialPayment',
  Amount = 'amount',
  Term = 'term',
}

export type LoanSelectionProps = {
  [InputNames.Amount]: number
  [InputNames.Term]: number
  [InputNames.Purpose]: string
  [InputNames.VehiclePrice]: number
  [InputNames.InitialPayment]: number
}

type LoanSelectionContextProps = {
  loanSelection: LoanSelectionProps
  setLoanSelection: (props: LoanSelectionProps) => void
}

/**
 * Create the context itself that is used within the
 * consumer and provider
 */
const LoanSelectionContext = React.createContext<LoanSelectionContextProps>({
  loanSelection: DEFAULT_SELECTION_VALUES,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoanSelection: () => {},
})

/**
 * The provider acts a wrapper to provide the context
 * to its children
 */
function LoanSelectionProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const { asPath } = useRouter()
  const path = asPath.substring(1)

  const queryParams = getQueryParamsFromPath(path)
  const loanSelectionFromQueryParams =
    getLoanSelectionFromQueryParams(queryParams)

  const [loanSelection, setPureLoanSelection] = React.useState(
    loanSelectionFromQueryParams,
  )

  const setLoanSelection = useCallback(
    (props: LoanSelectionProps) => {
      setPureLoanSelection(props)
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            ...props,
          },
        },
        undefined,
        { shallow: true },
      )
    },
    [router],
  )

  // Context values passed to consumer and exposed as getter and setter
  const value: LoanSelectionContextProps = useMemo(
    () => ({
      loanSelection,
      setLoanSelection,
    }),
    [loanSelection, setLoanSelection],
  )

  return (
    <LoanSelectionContext.Provider value={value}>
      {children}
    </LoanSelectionContext.Provider>
  )
}

/**
 * A convenience wrapper for the LoanSelectionConsumer
 */
const LoanSelectionConsumer = LoanSelectionContext.Consumer

/**
 * useLoanSelection Hook for easy use within functional components
 */
function useLoanSelection() {
  const context = React.useContext(LoanSelectionContext)
  return context
}

export { LoanSelectionProvider, LoanSelectionConsumer, useLoanSelection }
