/* eslint-disable @next/next/no-img-element */
import { memo } from 'react'

const SchufaNeutral = ({ image, url }: Record<string, string>) => (
  <a href={url} target='_blank' rel='noopener noreferrer'>
    <img
      src={image}
      alt='Ausgezeichnete Kundenzufriedenheit Logo'
      loading='lazy'
      height='64px'
    />
  </a>
)

export default memo(SchufaNeutral)
