import { memo } from 'react'
import { Theme, alpha, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CheckIcon from '@mui/icons-material/Check'

import { inIframe } from '../../utils/window/iframe'
import { scrollToTop } from '../../utils/window/scroll'
import { trackOverlayButtonClick } from '../../utils/segment'

const useStyles = makeStyles<Theme>((theme) => ({
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    maxHeight: '100%',
    overflow: 'auto',
    padding: `${theme.spacing(7.5)} ${theme.spacing(2.5)}`,
    background: alpha(theme.palette.common.black, 0.65),
    color: 'white',
    margin: 'auto -15px',

    [theme.breakpoints.up('sm')]: {
      margin: 'inherit',
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7.5),
    },
  },
  overlayTitle: {
    marginBottom: theme.spacing(5),
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  overlayList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  overlayListItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(5),
  },
  checkIcon: {
    fontSize: '1.125rem',
    marginRight: theme.spacing(1.5),
    color: theme.palette.success.main,
  },
}))

const OverlayText = () => {
  const { overlay, overlayTitle, overlayListItem, overlayList, checkIcon } =
    useStyles()

  const onClickHandler = () => {
    trackOverlayButtonClick()

    scrollToTop(inIframe())
  }

  return (
    <div className={overlay}>
      <Typography variant='h4' color='inherit' className={overlayTitle}>
        Warum wir Ihnen Angebote erst nach Eingabe persönlicher Daten anzeigen
      </Typography>
      <ul className={overlayList}>
        <li className={overlayListItem}>
          <CheckIcon className={checkIcon} />
          Bei uns gibt es keine Schaufenster-Zinsen.
        </li>
        <li className={overlayListItem}>
          <CheckIcon className={checkIcon} />
          Jeder Kreditgeber fragt nach Informationen zu Ihrer Person und
          finanziellen Situation, um Ihre Kreditwürdigkeit zu prüfen
        </li>
        <li className={overlayListItem}>
          <CheckIcon className={checkIcon} />
          Nach Eingabe dieser Daten erhalten Sie von uns einen Überblick über
          die für Sie verfügbaren Kreditangebote
        </li>
      </ul>
      <Button
        id='scroll-to-top'
        variant='contained'
        fullWidth
        color='primary'
        onClick={onClickHandler}
      >
        Kreditvergleich starten
      </Button>
    </div>
  )
}

export default memo(OverlayText)
